import './Card.css';
const Card = props => {
  const classes = 'card ' + props.type;
  return (
    <div className={classes} onClick={props.click} data-id={props.id}>
      <h3>{props.title}</h3>
      <small>{props.subtitle}</small>
    </div>
  );
};

export default Card;
