import './Quarterly.css';
import Quarter from './Quarter';
import Column from '../UI/Column';
import ColumnHeader from '../UI/ColumnHeader';
import ColumnItems from '../UI/ColumnItems';
const Quarterly = () => {
  const quarters = [
    {
      title: 'April',
      subtitle: '2021',
    },
  ];
  return (
    <Column>
      <ColumnHeader
        title="Quarterly"
        count={quarters.length}
        type="quarterlyHeader"
      />
      <ColumnItems>
        {quarters.map((quarter, idx) => {
          return (
            <Quarter
              key={idx}
              title={quarter.title}
              subtitle={quarter.subtitle}
            />
          );
        })}
      </ColumnItems>
    </Column>
  );
};

export default Quarterly;
