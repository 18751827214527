import classes from './Modal.module.css';

const Modal = props => {
  return (
    <>
      <div className={classes.modalWrap} onClick={props.close} />

      <div className={classes.modal}>
        <button className={classes.closeBtn} onClick={props.close}>
          X
        </button>

        {props.children}
      </div>
    </>
  );
};
export default Modal;
