import './ColumnHeader.css';
const ColumnHeader = props => {
  const classes = 'column-header ' + props.type;
  return (
    <div className={classes}>
      <div className="column-header__title">
        {props.title}{' '}
        <span className="column-header__title-count">{props.count}</span>
      </div>
      <button onClick={props.click}>+</button>
    </div>
  );
};

export default ColumnHeader;
