import React, { useState, useEffect } from 'react';
import Login from './Components/Login/Login';
import TopBar from './Components/TopBar/TopBar';
import Daily from './Components/Daily/Daily';
import Weekly from './Components/Weekly/Weekly';
import Monthly from './Components/Monthly/Monthly';
import Quarterly from './Components/Quarterly/Quarterly';
import Yearly from './Components/Yearly/Yearly';
import './App.css';
function App() {
  const [userSettings, setUserSettings] = useState({
    theme: 'dark',
  });

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const storeedUserLoggedInInfo = localStorage.getItem('isLoggedIn');
    +storeedUserLoggedInInfo === 1 && setIsLoggedIn(true);
  }, []);

  const loginHandler = (email, password) => {
    // We should of course check email and password
    // But it's just a dummy/ demo anyways
    localStorage.setItem('isLoggedIn', '1');
    setIsLoggedIn(true);
  };

  const logoutHandler = () => {
    localStorage.removeItem('isLoggedIn');
    setIsLoggedIn(false);
  };

  const [theme, setTheme] = useState(false);

  const themeChangeHandler = () => {
    setTheme(!theme ? true : false);
    setUserSettings(
      theme
        ? {
            theme: '',
          }
        : {
            theme: 'dark',
          }
    );
  };
  return (
    <>
      {!isLoggedIn && <Login onLogin={loginHandler} />}
      {isLoggedIn && (
        <div className={`App ${userSettings.theme}`}>
          <TopBar
            name="Roman"
            theme={themeChangeHandler}
            onLogout={logoutHandler}
          />
          <div className="boards">
            <Daily />
            <Weekly />
            <Monthly />
            <Quarterly />
            <Yearly />
          </div>
        </div>
      )}
    </>
  );
}

export default App;
