import './Monthly.css';
import Month from './Month';
import Column from '../UI/Column';
import ColumnHeader from '../UI/ColumnHeader';
import ColumnItems from '../UI/ColumnItems';
const Monthly = () => {
  const months = [
    {
      title: 'April',
      subtitle: '2021',
    },
  ];
  return (
    <Column>
      <ColumnHeader
        title="Monthly"
        count={months.length}
        type="monthlyHeader"
      />
      <ColumnItems>
        {months.map((month, idx) => {
          return (
            <Month key={idx} title={month.title} subtitle={month.subtitle} />
          );
        })}
      </ColumnItems>
    </Column>
  );
};

export default Monthly;
