import './Annualy.css';
import Year from './Year';
import Column from '../UI/Column';
import ColumnHeader from '../UI/ColumnHeader';
import ColumnItems from '../UI/ColumnItems';
const Annualy = () => {
  const annuals = [
    {
      title: '2021 New Year',
      subtitle: '2021',
    },
  ];
  return (
    <Column>
      <ColumnHeader
        title="Yearly"
        count={annuals.length}
        type="annualyHeader"
      />
      <ColumnItems>
        {annuals.map((annual, idx) => {
          return (
            <Year key={idx} title={annual.title} subtitle={annual.subtitle} />
          );
        })}
      </ColumnItems>
    </Column>
  );
};

export default Annualy;
