import React, { useState, useEffect } from 'react';
import './DailyData.css';
const ModalData = props => {
  const [info, setInfo] = useState(props.data.info);

  const dataChangeHandler = e => {
    const updatedInfo = info.map(item => item);
    updatedInfo[e.target.dataset.id].answer = e.target.innerHTML;
    info[e.target.dataset.id].answer = e.target.innerHTML;
    setInfo(updatedInfo);
    console.log('Info: ', ...info);
    console.log('updateInfo: ', ...updatedInfo);
  };
  // useEffect(() => {
  //   setInfo(info);
  // }, [info]);

  return (
    <>
      {/* DATA HEADER */}
      <div className="modal-data__header">
        <h1 className="modal-data__header-title">{props.data.title}</h1>
        <h3 className="modal-data__header-type">{props.type}</h3>
      </div>
      {/* DATA BODY */}
      <div className="modal-data__body" onInput={dataChangeHandler}>
        {info.map((item, idx) => (
          <div className="modal-data__question" key={idx}>
            <h2>{item.question}</h2>

            <p
              data-id={idx}
              suppressContentEditableWarning
              contentEditable
              spellCheck={false}
              onInput={props.edit}
              onPaste={e => {
                e.preventDefault();
                const text = e.clipboardData.getData('text');
                document.execCommand('insertText', false, text);
              }}
            >
              {item.answer}
            </p>
          </div>
        ))}
      </div>
    </>
  );
};

export default ModalData;
