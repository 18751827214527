const days = [
  {
    id: 1,
    title: 'Thursday, 14',
    subtitle: 'April, 2021',
    info: [
      {
        question: 'How I Feel?',
        answer: '😴 🙂 😊 😃 👍',
      },

      {
        question: 'Three things I am grateful for',
        answer: '1. Lorem, 2. Dolores 3. Sit a met',
      },
      {
        question: 'Looking forward to',
        answer: 'To finish daily compnent',
      },
      {
        question: 'What under my control would make today even more great',
        answer: 'Finish daily component',
      },
      {
        question:
          'If I lived even more consciously, freely, and courageously I would:',
        answer: 'I would travel around the world',
      },
    ],
  },
  {
    id: 2,
    title: 'Thursday, 15',
    subtitle: 'April, 2021',
    info: [
      {
        question: 'How I Feel?',
        answer: '😴 🙂 😊 😃 👍',
      },

      {
        question: 'Three things I am grateful for',
        answer: '1. Lorem, 2. Dolores 3. Sit a met',
      },
      {
        question: 'Looking forward to',
        answer: 'To finish daily compnent',
      },
      {
        question: 'What under my control would make today even more great',
        answer: 'Finish daily component',
      },
      {
        question:
          'If I lived even more consciously, freely, and courageously I would:',
        answer: 'I would travel around the world',
      },
    ],
  },
  {
    id: 3,
    title: 'Thursday, 16',
    subtitle: 'April, 2021',
    info: [
      {
        question: 'How I Feel?',
        answer: '😴 🙂 😊 😃 👍',
      },

      {
        question: 'Three things I am grateful for',
        answer: '1. Lorem, 2. Dolores 3. Sit a met',
      },
      {
        question: 'Looking forward to',
        answer: 'To finish daily compnent',
      },
      {
        question: 'What under my control would make today even more great',
        answer: 'Finish daily component',
      },
      {
        question:
          'If I lived even more consciously, freely, and courageously I would:',
        answer: 'I would travel around the world',
      },
    ],
  },
];

export default days;
