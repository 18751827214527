import React, { useState } from 'react';
import './Daily.css';
import Day from './Day';
import Column from '../UI/Column';
import ColumnHeader from '../UI/ColumnHeader';
import ColumnItems from '../UI/ColumnItems';
import Modal from '../UI/Modal';
import DailyData from './DailyData';
import days from './days';
const Daily = () => {
  const newDay = {
    id: Math.random(),
    title: 'Thursday, 14',
    subtitle: 'April, 2021',
    info: [
      {
        question: 'What did I dream?',
        answer: ' ',
      },
      {
        question: 'Three things I am grateful for',
        answer: ' ',
      },
      {
        question: 'Looking forward to',
        answer: ' ',
      },
      {
        question: 'What under my control would make today even more great',
        answer: ' ',
      },
      {
        question:
          'If I lived even more consciously, freely, and courageously I would:',
        answer: '',
      },
    ],
  };

  const [modalState, setModalState] = useState(false);

  const addItemHandler = () => {
    // setDays(prevState => {
    //   return [
    //     ...prevState,
    //     {
    //       id: 3,
    //       title: 'Thursday, 16',
    //       subtitle: 'April, 2021',
    //       info: 'Lorem ipsum dolores',
    //     },
    //   ];
    // });

    setModalState(true);
    setDataModal(newDay);
  };

  // CLOSE MODAL
  const closeModalHandler = e => {
    setModalState(null);
    setDataModal('');
  };

  const [dataModal, setDataModal] = useState({});

  const viewDataHandler = e => {
    const clickEl = +e.currentTarget.dataset.id;
    const curEl = days.find(day => day.id === clickEl);

    setModalState(true);
    setDataModal(curEl);
  };

  const editDataHandler = e => {
    // console.log(e.target.dataset);
    // console.log(dataModal);
  };

  return (
    <>
      {modalState && (
        <Modal close={closeModalHandler}>
          <DailyData data={dataModal} type="Daily" edit={editDataHandler} />
        </Modal>
      )}

      <Column>
        <ColumnHeader
          title="Daily"
          count={days.length}
          type="dailyHeader"
          click={addItemHandler}
        />

        <ColumnItems>
          {days.map(day => {
            return (
              <Day
                key={day.id}
                title={day.title}
                subtitle={day.subtitle}
                view={viewDataHandler}
                id={day.id}
              />
            );
          })}
        </ColumnItems>
      </Column>
    </>
  );
};

export default Daily;
