import './Weekly.css';
import Week from './Week';
import Column from '../UI/Column';
import ColumnHeader from '../UI/ColumnHeader';
import ColumnItems from '../UI/ColumnItems';
const Weekly = () => {
  const weeks = [
    {
      title: '7-14 April',
      subtitle: '2021',
    },
    {
      title: '7-14 April',
      subtitle: '2021',
    },
  ];
  return (
    <Column>
      <ColumnHeader title="Weekly" count={weeks.length} type="weeklyHeader" />
      <ColumnItems>
        {weeks.map((day, idx) => {
          return <Week key={idx} title={day.title} subtitle={day.subtitle} />;
        })}
      </ColumnItems>
    </Column>
  );
};

export default Weekly;
