// import './Day.css';
import Card from '../UI/Card';

const Day = props => {
  return (
    <Card
      title={props.title}
      subtitle={props.subtitle}
      type="day"
      click={props.view}
      id={props.id}
    />
  );
};

export default Day;
