import './TopBar.css';

const TopBar = props => {
  return (
    <div className="top-bar">
      <div className="top-bar__greeting">
        <h1>Hi,{props.name}</h1>
        <p>{Date().slice(0, 15)}</p>
      </div>
      <div className="top-bar__quote">
        <p>
          "Rome wasn’t built in a day, but they were laying bricks every hour.
          You don’t have to do it all today. Just lay a brick."
        </p>
      </div>
      <div>
        <button onClick={props.theme}>Change theme</button>
      </div>
      <button className="top-bar__settings" onClick={props.onLogout}>
        Logout
      </button>
    </div>
  );
};

export default TopBar;
